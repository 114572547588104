<!--
 * @Description: 
 * @Author: Chp
 * @Date: 2021-03-29 16:49:50
 * @LastEditTime: 2021-05-26 22:36:56
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <div class="concept">
    <SonTitle title="核心理念"></SonTitle>
    <div v-html="info.content"></div>
  </div>
</template>

<script>
export default {
  computed: {
    info () {
      return this.$store.state.IndexData.pinpai || {} // 取出数据
    }
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
  },
}
</script>

<style scoped lang='scss'>
/deep/ img {
  max-width: 100% !important;
}
</style>